<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner />
    <CTAFIXED value="een" />
    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon.svg" width="50" />
            <h3>Zo drinken Belgen het liefst hun koffie</h3>
          </div>
          <a class="link"
            href="https://www.senseo.be/?utm_source=article1&utm_medium=referral&utm_campaign=Wave1&utm_id=Native&utm_content=header-image"
            target="_blank">
            <img src="img/3790016_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" />
          </a>

          <div class="intro">
            <strong>
              Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
              landgenoten drinkt elke dag minstens één kop koffie. Sinds de
              corona-pandemie zit het koffiegebruik thuis zelfs enorm in de
              lift. En goed nieuws: we zijn ons ook steeds meer bewust van de
              impact van koffie op het milieu.
            </strong>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  België is een land van koffiedrinkers. Drie op vier van alle
                  volwassen Belgen drinkt minstens 1 kopje koffie per dag. Een
                  op drie drinkt 2 tot 3 kopjes per dag en minder dan een op
                  tien zit aan meer dan 6 kopjes per dag. Dat blijkt uit een
                  enquête door onderzoeksbureau iVOX bij 1.000 landgenoten.
                </p>
                <p>
                  Sinds het begin van de corona-pandemie kopen we bovendien meer
                  koffie dan vroeger. Logisch, want wat doe je als je thuis zit
                  of werkt en de lekkere koffie van op het werk mist? Dan sla je
                  extra koffie in. Gelukkig was het niet zo erg als het
                  hamstergedrag voor toiletpapier en chocopasta, maar toch: de
                  supermarkten verkochten in maart 2020 maar liefst 28% meer
                  koffie dan het jaar voordien. Ook na de eerste lockdown bleven
                  we meer koffie kopen.
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Filterkoffie blijft populair</h4>
              </div>

              <div class="content-text">
                <p>
                  Koffie kopen is één ding, maar hoe zet je die dan thuis? Ook
                  dat is onderzocht. Opvallend is dat nog een op vier dat op de
                  ouderwetse manier doet, met een koffiezetapparaat met filter.
                  Al is de populairste manier tegenwoordig die met het
                  koffiepadje: één op drie Belgen gebruikt namelijk een
                  koffieapparaat met pads. In dat segment is Senseo, dat precies
                  20 jaar geleden het systeem met padjes bij ons op de markt
                  bracht, de onbetwiste marktleider. Maar liefst 1,4 miljoen
                  Belgen gebruiken regelmatig
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262930&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">een Senseo-apparaat.</a>
                </p>

                <p>
                  Nog opvallend: de laatste jaren zijn we thuis we meer 'single
                  dose' koffie (pads en capsules) gaan drinken dan koffie in
                  bulk (bonen of gemalen). Niet zo gek natuurlijk, want als je
                  thuis werkt en tussen twee Teams-meetings een kopje koffie
                  wil, is een 'single dose' sneller en handiger dan een
                  traditionele koffiezet.
                </p>
                <img src="img/2_senseo-big-hover-machine-red-cup.png" class="image-content" alt="" />
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Melk of suiker?</h4>
              </div>

              <div class="content-text">
                <p>
                  Hoe we dan die koffie thuis drinken, is heel persoonlijk. Een
                  kleine minderheid (18%) van de Belgen drinken hun koffie
                  altijd zwart, zonder iets erbij. Meer dan de helft (57%) doet
                  melk of room bij de koffie. Een scheutje verse melk is het
                  meest in trek. Bij de koffiebereidingen is cappuccino het
                  populairst, op de voet gevolgd door latte macchiato en caffe
                  latte.
                </p>
                <p>
                  Het mag ook zoet zijn. 42% van de Belgen doen suiker bij de
                  koffie. Bij jonge koffiedrinkers (18 tot 29 jaar) zijn ook
                  chocolade (11%) en siropen (9%) in trek.
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>We vinden duurzaamheid belangrijk</h4>
              </div>

              <div class="content-text">
                <p>
                  Niet alleen de smaak, prijs en gebruiksgemak van onze koffie
                  doet ertoe, ook het duurzaamheidsaspect speelt een rol. Zeven
                  op tien houden bij aankoop van een koffiezet rekening met
                  duurzaamheid. Maar wat is nu de meest duurzame
                  koffiezetmethode? Volgens de meeste ondervraagden is dat de
                  klassieke koffiefilter met gemalen koffie. 'Single dose'
                  systemen met pads of capsules beschouwen we als minder
                  duurzaam, volgens de enquête. Maar klopt dat wel? Het blijkt
                  wat complexer dan dat. De impact van een kopje koffie hangt
                  vooral af van de koffie zelf en van de energie die nodig is om
                  water te verwarmen. Daardoor is een
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249412&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">'single dose' systeem vaak duurzamer</a>
                  dan filterkoffie.
                </p>
                <img src="img/resize-switch-white-hd6592_00_a5___171018015l.png" width="400" class="image-content two"
                  alt="" />
                <img src="img/resize-quadrante-black-hd7865_60_rft__160617022o.png" width="400"
                  class="image-content two" alt="" />

                <p>
                  Dat klinkt misschien vreemd, maar is logisch. Bij een 'single
                  dose' systeem wordt steeds weer de juiste dosis koffie
                  gebruikt en de juiste hoeveelheid water verwarmd. Omdat je met
                  een gewone koffiezet vaak meer koffie zet dan nodig en het
                  overschot in de gootsteen verdwijnt, scoort filterkoffie
                  slechter dan 'single dose' alternatieven.
                </p>

                <p>
                  Hoe jij je koffie ook graag drinkt, er is zeker
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262438&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">een Senseo die bij je past </a>. Dankzij het 'single dose' systeem zet je elke keer
                  een
                  perfect kopje koffie, op elk moment van de dag. Mede dankzij
                  de padjes uit papier is Senseo een duurzame manier om koffie
                  te zetten.
                </p>
              </div>
            </section>
          </div>
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
       
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                               postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"
                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div> 
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet
                  alleen: drie op vier Belgen drinkt elke dag koffie. Maar wat
                  is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
             <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is jouw favoriete koffie van ’t padje?" color="green" img="green">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft de manier waarop we
                  koffie drinken voorgoed veranderd...
                </p>
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="one" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);

    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },

  data() {
    return {};
  },
};
</script>

<style scoped>
.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #b06711;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #b06711;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}
</style>
