<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="vier" />
    <CTAFIXED value="vier" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon-red.svg" width="50" />
            <h3>Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?</h3>
          </div>

          <img src="img/ThierryDerycke.png" />

          <div class="intro">
            <strong>
              Dit jaar is Senseo twintig jaar op de Belgische markt. Een van de
              gebruikers van het eerste uur is Thierry. Wat zijn zijn ervaringen
              met Senseo?
            </strong>
          </div>


          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">

                <p>
                  Thierry (52) woont in Montigny-le-Tilleul, in de buurt van
                  Charleroi. Hij werkt als Chief Digital Official voor een
                  overheidsdienst. Hij kocht zijn eerste Senseo toen de nieuwe
                  machine pas op de markt kwam, vertelt Thierry. "Ik ben een
                  grote koffieliefhebber. Voor ik een Senseo kocht, gebruikte ik
                  een handmatige espressomachine en een percolator."
                </p>
                <p>
                  Het grote voordeel van de <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249427&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo</a> is de eenvoud, zegt Thierry.
                  "Het is niet gecompliceerd: je drukt op de knop om koffie te
                  zetten en klaar. De koffie smaakt altijd hetzelfde en het gaat
                  snel. Het toestel is gemakkelijk te onderhouden. Ik heb al
                  veel andere systemen gebruikt, maar ik kom altijd terug naar
                  mijn Senseo die ik voor niets anders zou willen ruilen."
                </p>
                <p>
                  "Wat ik ook fantastisch vind: de beste Senseo-machine is het
                  basisproduct, de gewone <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262441&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Original</a>. Mijn ouders hebben
                  een meer geavanceerd toestel, maar ik vind dat minder goed.
                  Het komt maar zelden voor dat het goedkoopste product ook het
                  beste is."
                </p>


              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">



                <div class="content-header">
                  <h4>Niet kapot te krijgen</h4>
                </div>

                <p>
                  Hoewel hij al bijna 20 jaar Senseo-koffie drinkt, is Thierry
                  nog maar aan zijn tweede toestel toe. "<a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262441&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">De machines</a> zijn
                  robuust. De espressomachines die ik voordien had, vielen
                  voortdurend in panne. Mijn eerste Senseo heb ik niet vervangen
                  omdat hij niet meer werkte, maar omdat hij door het jarenlange
                  gebruik wat moeilijker schoon te maken werd. Ik heb nog nooit
                  een product gevonden dat niet kapot gaat, behalve Senseo. Daar
                  ben ik heel tevreden over."
                </p>

                <p>
                  Naast de lange levensduur heeft Senseo nog meer ecologische
                  troeven, zegt Thierry. "Er is weinig afval. Bovendien is de
                  timing van de machines goed afgesteld: ze blijven lang genoeg
                  aan, maar niet té lang. Dat is een ecologisch voordeel dat
                  geen inspanning vereist. Misschien is filterkoffie nog iets
                  ecologischer, maar dan verlies je het gebruiksgemak van de
                  Senseo, en je hebt geen schuimlaagje." Thierry heeft een
                  suggestie om Senseo nog milieuvriendelijker te maken: "De
                  verpakking van de pads bevat vandaag nog een laagje
                  aluminiumfolie. Misschien is daar een ecologischer alternatief
                  voor te bedenken?"
                </p>
                <img src="img/koffie.png" class="image-content" alt="" />
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Betaalbare koffie voor iedereen</h4>
              </div>

              <div class="content-text">
                <p>
                  Thierry drinkt vier tassen per dag als hij op kantoor is, en
                  meer wanneer hij thuis werkt. "Met Senseo is lekkere koffie
                  betaalbaar voor iedereen. De prijs is lager dan bij andere
                  portiesystemen, waardoor je elke dag goede koffie kunt
                  drinken. Senseo is iets duurder dan filterkoffie. Maar bij
                  filterkoffie maken mensen soms meer koffie dan ze nodig
                  hebben; de rest gieten ze dan weg. Bij Senseo maak je steeds
                  precies zoveel koffie als je wil. Je giet niets weg, dus dat
                  is sowieso beter."
                </p>
                <p>
                  Thierry drinkt het liefst de <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262441&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Classic</a> (rode verpakking)
                  en <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262936&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Decaf</a> (blauwe verpakking) koffie, en hij heeft ook
                  de nieuwe <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249415&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Compact Pads</a> al eens geprobeerd.
                </p>
                <p>
                  Al bij al zou Thierry niets anders willen dan Senseo. "Het is
                  een product dat goed gepositioneerd is, het gaat niet stuk,
                  het is betrouwbaar en makkelijk schoon te maken. Ik vind dat
                  Senseo zelf veel te weinig communiceert over hoe eenvoudig het
                  systeem wel is. Het heeft de juiste balans tussen plezier en
                  prijs."
                </p>
                <img src="img/Goede-goedkope-senseo.png" class="image-content" alt="" />
              </div>
            </section>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                               postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"
                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div> 
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie " color="bruin" img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie. Sinds de
                  corona-pandemie zit het koffiegebruik...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is jouw favoriete koffie van ’t padje?" color="green" img="green">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft...
                </p>
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet alleen: drie op vier Belgen drinkt elke dag
                  koffie. Maar wat is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="vier" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
   if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #bc1522;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #bc1522;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #e14337;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}
</style>
