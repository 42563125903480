import Vue from 'vue/dist/vue.common.js';

import App from './App.vue';
import VueRouter from 'vue-router'
import router from './routes.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
