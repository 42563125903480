<template>
  <div class="rg">
    <div class="cta-padding" v-show="doShow">
      <img
        src="/img/lofo-senseo.png"
        class="img-fluid"
        style="max-width: 120px"
      />
      <br /><br />



      <p>Ontdek het hele gamma Senseo koffiemachines en pads</p>
      <br />

      <div class="content-buttons">
 

        <a
        target="_blank"
          :href="value=='een' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=5947249409&iu=/22306128931/DFPAudiencePixel' : value=='two' ?'http://pubads.g.doubleclick.net/gampad/clk?id=5947249415&iu=/22306128931/DFPAudiencePixel' :value=='three' ?'http://pubads.g.doubleclick.net/gampad/clk?id=5947249424&iu=/22306128931/DFPAudiencePixel' : value== 'vier' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6011373354&iu=/22306128931/DFPAudiencePixel' : value== 'vijf' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6011373480&iu=/22306128931/DFPAudiencePixel' :   value == 'zes' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787064&iu=/22306128931/DFPAudiencePixel' : value == 'zeven' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787076&iu=/22306128931/DFPAudiencePixel' :  'http://pubads.g.doubleclick.net/gampad/clk?id=5947249409&iu=/22306128931/DFPAudiencePixel' "
          class="btn btn-primary"
          >bekijk nu</a
        >
      </div>
    </div>
    <div style="position: absolute; top: 5px; left: 5px">
      <button
        @click="doShowM"
        id="hidectabtn"
        style="color: #ffffff; background: none; height: auto; border: none"
      >
        <img
          src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/000000/external-delete-ui-dreamstale-lineal-dreamstale.png"
        />
      </button>
    </div>
  </div>
</template>

<style >
.rg {
  position: fixed;
  top: 155px;
  right: 0 !important;
  max-width: 250px;
  background: #fff;
  color: #000;
  z-index: 999999999;
  font-size: 145x;
  line-height: 20px;
}
.cta-padding {
  padding: 30px 30px 30px 30px;
  border-top: 1px solid #e6e7e9;
  border-left: 1px solid #e6e7e9;
  border-bottom: 1px solid #e6e7e9;
}
.ctatoplink {
  font-family: "Roboto Slab", serif;
  font-size: 22px;
  font-weight: normal;
  text-transform: none;
  color: #ffffff;
  text-decoration: none;
}
.btn.btn-primary {
  margin-top: 0 !important;
  font-size: 19px !important;
  
}
</style>
<script>
export default {
    props: ['value'],
  data() {
    return {
      doShow: true,
    };
  },
  methods: {
    doShowM() {
      this.doShow = false;
    },
  },
};
</script>




