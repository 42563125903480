<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="two" />
    <CTAFIXED value="three" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon-geel.svg" width="50" />
            <h3>Belgen verkiezen duurzame koffie, jij toch ook?</h3>
          </div>
          <a class="link"
            href="https://www.senseo.be/duurzaamheid/?utm_source=article3&utm_medium=referral&utm_campaign=Wave1&utm_id=Native&utm_content=header-image "
            target="_blank">
            <img src="img/3790019_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" />
          </a>
          <div class="intro">
            <strong>
              Twee op drie Belgische koffiedrinkers willen het liefst duurzame
              koffie en dat iedereen in de koffieketen zijn verantwoordelijkheid
              neemt, ook de producenten van koffiemachines. Maar weet jij hoe
              duurzaam jouw kopje koffie nu echt is? Ontdek het hier en kom te
              weten hoe jij duurzamere koffiekeuzes kan maken.
            </strong>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  Vraag jij je af wat de impact van je kop koffie op het milieu
                  is? De helft van de Belgische koffiedrinkers alvast wel. Twee
                  op drie koffiedrinkers vindt ook dat het hun
                  verantwoordelijkheid is om milieuvriendelijke keuzes te maken.
                  Dat blijkt uit een bevraging door onderzoeksbureau iVOX bij
                  1.000 Belgische koffiedrinkers.
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Duurzame koffie</h4>
              </div>

              <div class="content-text">
                <p>
                  Om de milieu-impact van een kop koffie te berekenen, wordt de
                  CO2-uitstoot van koffieboon tot kopje gemeten.
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262945&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">
                    De teelt van de koffiebonen heeft de grootste impact</a>: tot 68% van de totale uitstoot. Op de
                  tweede plaats volgt
                  de consumptie (koffie zetten, afvalverwerking en afwassen). De
                  rest van de uitstoot wordt veroorzaakt door het transport, het
                  koffiebranden, de verpakking en de distributie naar de winkel.
                </p>
                <p>
                  Doordat de teelt zo zwaar doorweegt in de uitstoot, is hier de
                  grootste milieuwinst te halen. Als consument heb je dat mee in
                  de hand. Je kan namelijk kiezen voor koffie die op duurzame
                  wijze geproduceerd wordt. Hoe je dat kan weten? Door te letten
                  op de labels op koffie.
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262450&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Koffie met het Rainforest Alliance (RFA) label</a>
                  wordt geteeld onder goede arbeidsomstandigheden en met een
                  beperkte impact op het leefmilieu. Dat label krijg je niet
                  zomaar: het wordt toegekend door een onafhankelijke
                  organisatie die er streng op toekijkt dat de normen gevolgd
                  worden.
                </p>
                <p>
                  Voor zijn handige padjes kiest
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249427&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo</a>
                  uitsluitend
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262948&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">voor duurzame koffie</a>. "Wij kopen onze koffie van boerderijen die gecertificeerd
                  zijn door Rainforest Alliance", zegt brand manager Goele
                  Smeyers van Senseo. "Zo dragen we bij aan betere
                  landbouwpraktijken, de strijd tegen de klimaatcrisis en het
                  behoud van bossen. We zijn ook zeker dat de rechten van boeren
                  en arbeiders gerespecteerd worden, en dat ze een behoorlijk
                  inkomen krijgen.”
                </p>
                <img src="img/Naamloos-4.png" class="image-content" alt="" />
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Slimmer consumeren</h4>
              </div>

              <div class="content-text">
                <p>
                  De manier waarop we koffie zetten heeft – na de koffie zelf –
                  de grootste impact op het milieu. En daar kan jij zelf iets
                  aan doen! Uit onderzoek blijkt dat men denkt dat traditionele
                  filterkoffie de meest duurzame optie is. Klinkt
                  vanzelfsprekend, maar het is iets ingewikkelder dan dat. We
                  zeiden al dat de koffieteelt zelf de grootste impact heeft.
                  Daarom is het vooral belangrijk dat je niet meer koffie
                  gebruikt dan nodig. Hetzelfde geldt voor het verwarmen van
                  water, wat veel energie vraagt. Als je meer water opwarmt dan
                  nodig, is ook je milieu-impact groter.
                </p>
                <p>
                  Een 'single dose' systeem met pads kan daardoor duurzamer zijn
                  dan filterkoffie: elk padje bevat exact de juiste hoeveelheid
                  koffie en de machine verwarmt de juiste hoeveelheid water die
                  nodig is om dat kopje koffie te zetten. "We hebben een
                  onafhankelijk gecontroleerde studie laten uitvoeren over de
                  totale ecologische impact van een kopje koffie", zegt Goele
                  Smeyers. "Daaruit bleek dat het Senseo koffiepadsysteem
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262453&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">een lage ecologische impact heeft </a>in vergelijking met tal van andere populaire
                  koffiezetapparaten, waaronder een koffiezet voor
                  filterkoffie." Een enkel padje in jouw Senseo is dus duurzamer
                  dan een kan filterkoffie die niet helemaal opgedronken wordt.
                </p>
                <img src="img/Naamloos-3.png" class="image-content" alt="" />
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Composteerbare pads</h4>
              </div>

              <div class="content-text">
                <p>
                  Na het zetten heeft filterkoffie één voordeel: het gruis en de
                  filter mogen in de gft-bak of op de composthoop. Voor de
                  koffiepads van Senseo was dat lang niet het geval. Daarom
                  heeft Senseo ervoor gezorgd dat
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249430&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">zijn merkeigen koffiepads voor zwarte koffie industrieel
                    gecomposteerd kunnen worden.</a>
                  "Koffiepads bestaan voor 97,6% uit koffie, voor 1,7% uit
                  papier en voor 0,7% uit een soort lijm die de beide helften
                  van de pad verbindt", legt Goele Smeyers uit. "We zijn erin
                  geslaagd om een lijm te ontwikkelen uit een composteerbaar,
                  plantaardig materiaal. Daardoor kunnen onze pads voor zwarte
                  koffie gecomposteerd worden in een composteringsinstallatie."
                </p>
              </div>
            </section>
          </div>
          <div class="upper-content flex justify-center">
            <section class="content lazy text">
              <img src="img/SENSEO-Bag_Angle2.png" width="50%" alt="" />
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Reduceren en compenseren</h4>
              </div>

              <div class="content-text">
                <p>
                  Op de andere schakels in de keten – transport, koffiebranden,
                  verpakking – heb je als koffiedrinker minder invloed. Gelukkig
                  proberen veel bedrijven zelf de milieu-impact van hun
                  producten te reduceren. Dat doen ze ondermeer door meer
                  gerecycleerde materialen te gebruiken voor de toestellen zelf
                  en de verpakking. Bij Senseo bestaat de verpakking
                  bijvoorbeeld al voor meer dan 90% uit gerecycleerd karton.
                </p>

                <p>
                  Een deel van de koolstofuitstoot is nog niet te vermijden.
                  Daarom sloot Senseo een overeenkomst met de ngo
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262954&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Graine de Vie</a>. Sinds 2020 wordt de uitstoot van Senseo gecompenseerd door
                  een herbebossingscampagne op Madagaskar.
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249433&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Er werden al meer dan 280.000 bomen geplant.</a>
                  Graine de Vie zet op elk van de terreinen een kwekerij op
                  poten en leidt lokale mensen op om deze te beheren. Door dit
                  initiatief is Senseo koffie officieel koolstofneutraal.
                </p>
              </div>
            </section>
          </div>
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                               postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"
                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div> 
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie " color="bruin" img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie. Sinds de
                  corona-pandemie zit het koffiegebruik...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is jouw favoriete koffie van ’t padje?" color="green" img="green">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft...
                </p>
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet
                  alleen: drie op vier Belgen drinkt elke dag koffie. Maar wat
                  is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="drie" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
       if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }

  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #fecc1f;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #fecc1f;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #e14337;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}
</style>
