<template>
  <div class="banner-img"   style="background-image: url(https://senseo.nadruksubsites.be/img/koffiezet_hover_1143x960.png)">
    <div
      class="footer-1"

          :style="[value == 'een' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner1.svg)'} :
    value == 'two' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner2.svg)'} :
     value == 'vier' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner4.svg)'} :
       value == 'vijf' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner5.svg)'} :
                value == 'zeven' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner1.svg)'} :
     {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner.svg)'}]"
    >
      <img
        src="img/vantpadje_wit.svg"
        class="img-footer"
        width="100"
        height="100"
      />
      <div class="content-header">
        <h2 class="cta">Geniet van de volle <br />en rijke smaak!</h2>
      </div>
    </div>
    <div class="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
      <div class="sm:flex"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style scoped>
footer {
  position: relative;
}
.copy-footer {
  font-family: sans-serif !important;
  opacity: 0.7;
  color: #b06711;
  padding-top: 20px;
}
.footer-1 {
  font-family: "Senseo-Sentence,Helvetica,Arial,sans-serif";
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 382px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 194px;
  position: absolute;
  bottom: 0;
}
footer {
  padding: 0;
  position: relative;
}

.banner-img {
  height: 500px;
  background-repeat: no-repeat;
  position: relative;
  background-position: 53% 69%;
  background-size: cover;
}
.img-footer {
  position: absolute;
  top: 6%;
  left: 40%;
  width: 264px;
}
.banner1 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner1.svg);
}

.banner2 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner2.svg);
}
.banner3 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner.svg);
}
.banner4 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner4.svg);
}
.banner5 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner5.svg);
}
.banner6 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner6.svg);
}
.banner7 {
  background-image: url(https://senseo.nadruksubsites.be/img/footer-banner1.svg);
}
@media (max-width: 784px) {
  .banner3 {
    background-color: #b06711a1;
    background-image: none;
    height: 336px;
  }
  .banner1 {
    background-color: #30a44d85;
    background-image: none;
    height: 336px;
  }
  .banner2 {
    background-color: #fecc1d94;
    background-image: none;
    height: 336px;
  }
  .banner4 {
    background-color: #bc1522cf;
    background-image: none;
    height: 336px;
  }
  .banner5 {
    background-color: #4a1a188c;
    background-image: none;
    height: 336px;
  }
  .img-footer {
    top: 18%;
    left: 16%;
  }
  .banner-img {
    height: 335px;
  }
}
</style>