<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="zes" />
    <CTAFIXED value="zes" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon.svg" width="50" />
            <h3>
              Henja van den Elzen (JDE): "Geloof me, er wordt heel wat koffie
              geproefd in ons team."
            </h3>
          </div>
 <a class="link"
            href="http://pubads.g.doubleclick.net/gampad/clk?id=6095784898&iu=/22306128931/DFPAudiencePixel"
            target="_blank">
          <img src="img/edit_DSC8365.png" />
          </a>

          <div class="intro">
            <strong>
              Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts
              (JDE) verantwoordelijk voor de productontwikkeling van Senseo.
              Want er komt heel wat bij kijken om ervoor te zorgen dat elke
              koffiepad altijd een perfect kopje koffie oplevert.
            </strong>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  'R&D Category Manager Single Serve', staat op het naamkaartje
                  van Henja van den Elzen. "Ik ben verantwoordelijk voor
                  onderzoek en ontwikkeling van alles wat met Senseo te maken
                  heeft. We onderzoeken bijvoorbeeld nieuwe koffievarianten die
                  beantwoorden aan wat de consument vandaag wil. Geloof me, er
                  wordt heel wat koffie geproefd in ons team! We hebben daar
                  speciale smaakpanels voor, met medewerkers die getraind zijn
                  om smaken te onderscheiden en te beoordelen. Die smaakpanels
                  gebruiken ook een vaste methode om koffie te proeven. Als het
                  smaakpanel overtuigd is, doen we vaak ook nog smaaktests met
                  gewone koffiedrinkers, om na te gaan of zij even enthousiast
                  zijn."
                </p>
                <p>
                  Een nieuwe koffievariant wordt eerst uitgetest in de
                  proeffabriek van Senseo in Utrecht. "Daar kunnen we op kleine
                  schaal pads met de nieuwe variant maken. Die testen we dan
                  uit: smaken ze zoals we willen, werken ze in alle bestaande
                  Senseo-machines, moet er iets aan de verpakking veranderen?
                  Pas als die tests allemaal achter de rug zijn, wordt de
                  productie in de reguliere fabriek gestart."
                </p>
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <div class="content-header">
                  <h4>Wat wil de consument?</h4>
                </div>

                <p>
                  Het onderzoek wordt gestuurd door wat de consument wil. JDE
                  doet daarvoor heel wat marktonderzoek waarbij gewone
                  consumenten ondervraagd worden. Het team van Henja volgt ook
                  nauwlettend de trends in koffieconsumptie op. "Er zijn externe
                  studiebureaus die zich daarmee bezig houden. Maar ik doe het
                  ook zelf informeel: als ik in de supermarkt rondloop, kan ik
                  het niet nalaten om het aanbod te bekijken. Ook als ik in een
                  restaurant of op bezoek bij vrienden ben, hou ik in de gaten
                  wat voor koffie er is, hoe mensen hun koffie drinken,… Maar
                  het is niet zo dat ik de hele tijd met mijn werk bezig ben,
                  hoor. Ik vind het fijn om gezellig samen te zijn met familie
                  of vrienden en een leuk gesprek te voeren – en daar hoort
                  natuurlijk koffie bij", lacht Henja.
                </p>

                <p>
                  Toen Senseo 20 jaar geleden op de markt kwam, waren er vier
                  soorten pads verkrijgbaar. Vandaag zijn dat er <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6097277675&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">veertien</a>
                  "Senseo was oorspronkelijk ontwikkeld voor wat wij 'long
                  black' noemen, dus zwarte koffie, met het kenmerkende
                  crema-laagje dat je voordien alleen met een espresso-apparaat
                  had. Consumenten vroegen ons dan of ze ook cappuccino in hun
                  Senseo konden maken. Daaruit zijn dan de Senseo Cappuccino
                  pads ontstaan, met een laagje melkschuim in plaats van de
                  koffie crema."
                </p>
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Uitgebreid testen</h4>
              </div>

              <div class="content-text">
                <p>
                  Een recentere trend was de vraag naar koffie met een nog
                  intensere smaak. Die heeft geleid tot de  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6095787061&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">nieuwe compacte pads</a>
                  met 100% Arabica koffie. Met de kwalitatief hoogwaardige
                  koffie, gebrand, gemalen en verpakt in de compacte pads krijg
                  je een stevige, espresso-achtige koffie.
                </p>
                <p>
                  Voor het ontwikkelteam was dat een hele uitdaging, vertelt
                  Henja. "We moesten een pad ontwerpen die steviger is dan de
                  gewone pad, waar de koffie wat strakker in zit. Maar die pads
                  moeten wel gewoon werken in alle bestaande Senseo-apparaten.
                  Dat moest dus uitgebreid getest worden voor de productie op
                  grote schaal kon beginnen."
                </p>
                <p>
                  De compacte pads hebben ook een andere verpakking. Daarvoor
                  moest het team van Henja nauw samenwerken met de ingenieurs
                  die de verpakkingsmachines ontwikkelen.
                </p>
              </div>
                              <img src="img/edit_DSC8385.png" class="image-content" alt="" />

            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Composteerbare pads</h4>
              </div>

              <div class="content-text">
                <p>
                  Henja is erg trots op het feit dat Senseo-pads tegenwoordig
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6095094618&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">industrieel gecomposteerd</a> kunnen worden. "Al van in het begin
                  is duurzaamheid een kernwaarde voor Senseo, dus het lag voor
                  de hand dat we onze pads composteerbaar wilden maken."
                </p>
                <p>
                  Het filterpapier dat gebruikt wordt voor de Senseo-pad bestaat
                  uit een mengsel van papiervezels en een biomateriaal-vezel.
                  Het biomateriaal zorgt er voor dat de twee stukjes
                  filterpapier aan elkaar geseald kunnen worden. Het nieuwe
                  materiaal werd goed onder de loep gelegd, vertelt Henja. "We
                  hebben de samenstelling van het papier aangepast, zodat het
                  biologische afbreekbaar en industrieel composteerbaar is,
                  volgens de relevante norm EN13432. Daarbij moet je natuurlijk
                  nagaan of het nieuwe papier de smaak niet verandert. Behalve
                  dat, wil je niet dat je pads openscheuren doordat je een ander
                  papier gebruikt. De nieuwe pads moesten dan nog uitvoerig
                  getest worden voor de productie kon starten. Reken alles samen
                  toch op een paar jaar onderzoek en testen voor je van een idee
                  tot een echt product in de winkelrekken komt."
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Werken aan de toekomst</h4>
              </div>

              <div class="content-text">
                <p>
                  Dit jaar viert JDE de twintigste verjaardag van de introductie
                  van Senseo. Het team van Henja werkt al volop aan de volgende
                  twintig jaar. "We blijven heel goed in de gaten houden wat de
                  consument belangrijk vindt. Vandaag is duurzaamheid een hot
                  topic, en we zijn voortdurend aan het werk om onze machines,
                  pads en koffies zo duurzaam mogelijk te maken", besluit Henja.
                </p>
              </div>
            </section>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"
                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet
                  alleen: drie op vier Belgen drinkt elke dag koffie. Maar wat
                  is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie " color="bruin" img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie. Sinds de
                  corona-pandemie zit het koffiegebruik...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is jouw favoriete koffie van ’t padje?" color="green" img="green">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft...
                </p>
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="zes" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
   if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.link {
  color: #b06711;
  font-weight: bold;
  text-decoration: underline !important;
}

.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #b06711;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #b06711;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #b06711;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}

.cursief {
  font-size: 12px;
  font-style: italic;
  margin: 0;
}
</style>
