var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-img",style:([_vm.img == 'green' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'} :
  _vm.img == 'geel' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790019_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'} :
   _vm.img == 'red' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/ThierryDerycke.png)'} :
     _vm.img == 'donkerbruin' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/senseo.png)'} :
            _vm.img == 'beige' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/banner-img-beige.png)'} :
              _vm.img == 'groen-zeven' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/Sonja_IMG_0087.png)'} :
   {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790016_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'}])},[_c('div',{staticClass:"small-banner",style:([_vm.color == 'green' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-green.svg)'} :
  _vm.color == 'geel' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-one.svg)'} :
   _vm.color == 'red' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-red.svg)'} :
     _vm.color == 'donkerbruin' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-donkerbruin.svg)'} :
            _vm.color == 'beige' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-beige.svg)'} :
              _vm.color == 'groen' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-green.svg)'} :
   {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner.svg)'}])},[_c('div',{staticClass:"content-header"},[_c('h3',{staticClass:"h3"},[_vm._v(_vm._s(_vm.postTitle))])])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0"},[_c('div',{staticClass:"sm:flex"})])}]

export { render, staticRenderFns }