import Article1 from "./components/blogs/Article1";
import Article3 from "./components/blogs/Article3";
import Article4 from "./components/blogs/Article4";

import Article2 from "./components/blogs/Article2";
import Article5 from "./components/blogs/Article5";
import Article6 from "./components/blogs/Article6";
import Article7 from "./components/blogs/Article7";
import HelloWorld from "./components/HelloWorld";

import VueRouter from "vue-router";
let routes = [
  { path: "/", component: HelloWorld, name: "HelloWorld" },

  {
    path: "/zo-drinken-belgen-het-liefst-hun-koffie",
    component: Article1,
    name: "Article1",
  },
  {
    path: "/wat-is-jouw-favoriete-koffie-van-t-padje",
    component: Article2,
    name: "Article2",
  },
  {
    path: "/belgen-verkiezen-duurzame-koffie-jij-toch-ook",
    component: Article3,
    name: "Article3",
  },
  {
    path: "/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie",
    component: Article4,
    name: "Article4",
  },
  {
    path: "/wat-is-de-voordeligste-manier-om-koffie-te-zetten",
    component: Article5,
    name: "Article5",
  },
  {
    path: "/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie",
    component: Article6,
    name: "Article6",
  },
  
  {
    path: "/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk",
    component: Article7,
    name: "Article7",
  },
];

export default new VueRouter({  routes });
