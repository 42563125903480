<template>
  <div id="app">
    <router-view class="view" ></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
  @import './assets/css/tailwind.css';
</style>