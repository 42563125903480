<template>
  <div class="head">
    <div v-if="cta == 'one'">
      <Cta text="Senseo, dat is al twintig jaar de  handigste manier om koffie te zetten.  " url="one" />
    </div>
    <div v-else-if="cta == 'two'">
      <Cta
        text=" Drink jij liefst een lungo, of verkies je een espresso? Of toch maar een cappuccino of latte? Er is zeker een Senseo koffiemachine die bij je past.  "
        url="two" />
    </div>
    <div v-else-if="cta == 'drie'">
      <Cta
        text=" Vind jij duurzaamheid ook zo belangrijk voor jouw dagelijkse kopje koffie? Met de Senseo koffiemachines en koffiepads maak je de juiste keuze. "
        url="drie" />
    </div>
    <div v-else-if="cta == 'vier'">
      <Cta
        text="Wil jij ook een dagelijks kopje koffie voor een betaalbare prijs? Met de duurzame Senseo koffiemachines en koffiepads maak je de juiste keuze. Klik hier en ontdek het hele gamma koffiemachines en pads."
        url="vier" />
    </div>
    <div v-else-if="cta == 'vijf'">
      <Cta
        text="Wil jij ook een dagelijks kopje koffie voor een betaalbare prijs? Met de duurzame Senseo koffiemachines en koffiepads maak je de juiste keuze. Klik hier en ontdek het hele gamma koffiemachines en pads."
        url="vijf" />
    </div>
        <div v-else-if="cta == 'zes'">
      <Cta
        text="Wil jij ook een dagelijks kopje koffie voor een betaalbare prijs? Met de duurzame Senseo koffiemachines en koffiepads maak je de juiste keuze. Klik hier en ontdek het hele gamma koffiemachines en pads."
        url="zes" />
    </div>
           <div v-else-if="cta == 'zeven'">
      <Cta
        text="Wil jij ook een dagelijks kopje koffie voor een betaalbare prijs? Met de duurzame Senseo koffiemachines en koffiepads maak je de juiste keuze. Klik hier en ontdek het hele gamma koffiemachines en pads."
        url="zeven" />
    </div>
    <div v-else>
      <section class="app-grid lazy register-homepage-block top-cup mt-5" style="
          background-image: url(img/register-texture.png);
          margin-bottom: 0px !important;
        ">
        <img src="/img/coffee-cup.png" width="300" class="coffee-img" height="300" alt="" />
        <div class="container">
          <div class="row">
            <div class="
                col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
                right-content-mpbil
              ">
              <section class="content lazy mt-2">
                <div class="content-header">
                  <h4 class="tr mt-5">Senseo, dat is al twintig jaar de handigste manier om koffie te zetten.</h4>
                </div>

                <div class="content-text"></div>

                <div class="content-buttons">
                  <a target="_blank"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262447&iu=/22306128931/DFPAudiencePixel"
                    class="btn btn-primary cta">Klik hier en ontdek het hele gamma <br />
                    Senseo koffiemachines en pads.</a>
                </div>
              </section>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left-content"
              style="background-image: url(img/register-texture-mobile.png)">
              <section class="content lazy">
                <div class="content-header">
                  <h2 class="cta">Nóg meer <br />koffieplezier!</h2>
                </div>
              </section>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right-content">
              <section class="content lazy mt-2">
                <div class="content-header">
                  <h4 class="tr mt-5">Senseo, dat is al twintig jaar de handigste manier om koffie te zetten.</h4>
                </div>

                <div class="content-text"></div>

                <div class="content-buttons">
                  <a target="_blank"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262447&iu=/22306128931/DFPAudiencePixel"
                    class="btn btn-primary">Klik hier en ontdek het hele gamma <br> Senseo koffiemachines en
                    pads.</a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer>
      <div class="footer-1" style="background-image: url(img/footer.svg)">
        <div class="">
          <span style="font-size: 14px" class="type--fine-print copy-footer text-gray-700">© Copyright <span
              class="update-year"> 2022</span> Gemaakt
            door&nbsp;Ads &amp; Data Brand Studio&nbsp;in opdracht van Senseo .
            <br />
            De inhoud van deze pagina's valt niet onder de verantwoordelijkheid
            van de redactie.</span>
        </div>
      </div>
      <div class="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
        <div class="sm:flex"></div>
      </div>
    </footer>
  </div>
</template>
<script>
import Cta from "../components/CTA.vue";

export default {
  components: {
    Cta,
  },
  props: ["cta"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.head {
  position: relative;
}

.copy-footer {
  font-family: sans-serif !important;
  opacity: 0.7;
  color: #000;
  padding-top: 20px;
}

.footer-1 {
  font-family: "Senseo-Sentence,Helvetica,Arial,sans-serif";

  top: -58px;
  position: absolute;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;

  background-position: center;
  background-repeat: no-repeat;

  padding-top: 143px;

  padding-bottom: 80px;
}

@media (max-width: 784px) {
  .home-footer .head {
    margin-top: 220px;
  }

  .footer-1 {
    top: -98px;
  }
}

footer {
  padding: 0;
  position: relative;
}

.img-footer {
  position: absolute;
  top: 0;
  left: 47%;
}
</style>
<style scoped>
.btn.btn-primary {
  margin-top: 0 !important;
  font-size: 19px !important;
  background-color: #d00e2f;
  color: #fff;
  border-radius: 20.5px;
  padding: 0 24px;
  line-height: 21px;
  border: none;
  margin-top: 30px !important;
  height: 67px !important;

  padding-top: 11px !important;
}
</style>