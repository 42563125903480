<template>




  <div
    class="banner-img"

    :style="[img == 'green' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'} :
    img == 'geel' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790019_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'} :
     img == 'red' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/ThierryDerycke.png)'} :
       img == 'donkerbruin' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/senseo.png)'} :
              img == 'beige' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/banner-img-beige.png)'} :
                img == 'groen-zeven' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/Sonja_IMG_0087.png)'} :
     {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/3790016_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png)'}]"

  >
    <div
      class="small-banner"
        :style="[color == 'green' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-green.svg)'} :
    color == 'geel' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-one.svg)'} :
     color == 'red' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-red.svg)'} :
       color == 'donkerbruin' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-donkerbruin.svg)'} :
              color == 'beige' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-beige.svg)'} :
                color == 'groen' ? {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner-green.svg)'} :
     {backgroundImage: 'url(https://senseo.nadruksubsites.be/img/footer-banner.svg)'}]"

    >
      <div class="content-header">
        <h3 class="h3">{{ postTitle }}</h3>
      </div>
    </div>
    <div class="px-4 md:w-1/4 md:ml-auto mt-6 sm:mt-4 md:mt-0">
      <div class="sm:flex"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["postTitle", "color", "img"],
  data() {
    return {};
  },
};
</script>

<style scoped>
footer {
  position: relative;
}
.copy-footer {
  font-family: sans-serif !important;
  opacity: 0.7;
  color: #a2a2a2;
  padding-top: 20px;
}
.small-banner {
  font-family: "Senseo-Sentence,Helvetica,Arial,sans-serif";
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 58px;
  position: absolute;
  bottom: 0;
}
footer {
  padding: 0;
  position: relative;
}

.banner-img {
  height: 300px;
  background-repeat: no-repeat;
  position: relative;
  background-position: 53% 69%;
  background-size: cover;
}
.img-footer {
  position: absolute;
  top: 10%;
  left: 47%;
}
.h3 {
  font-size: 14px;
  font-family: "Flama-Basic", sans-serif;
  color: #fff;
  font-weight: bold;
  padding: 0 20px;
}
.color {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner-green.svg');
}
.color4 {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner-red.svg');
}
.color1 {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner-one.svg');
}
.color2 {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner.svg');
}
.color5 {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner-donkerbruin.svg');
}
.color6 {
  background-image: url('https://senseo.nadruksubsites.be/img/footer-banner-beige.svg');
}
.banner-img-green {
  background-image: url('https://senseo.nadruksubsites.be/img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png');
}
.banner-img-groen-zeven {
  background-image: url('https://senseo.nadruksubsites.be/img/Sonja_IMG_0087.png');
}

.banner-img-geel {
  background-image: url('https://senseo.nadruksubsites.be/img/3790019_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png');
}
.banner-img-bruin {
  background-image: url('https://senseo.nadruksubsites.be/img/3790016_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png');
}
.banner-img-red {
  background-image: url('https://senseo.nadruksubsites.be/img/ThierryDerycke.png');
}
.banner-img-beige {
  background-image: url('https://senseo.nadruksubsites.be/img/banner-img-beige.png');
}
.banner-img-donker-bruin {
  background-image: url('https://senseo.nadruksubsites.be/img/senseo.png');
}
</style>
