<template>
  <div class="main-container">

    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <div class="banner-img-home" style="background-image: url(img/home-header.jpg)">
      <div class="banner-1" style="background-image: url(img/footer-banner.svg)"></div>
      <div class="px-4 md:w-1/4 md:ml-auto">
        <div class="sm:flex mt-img-content flex items-center align-sub	 ">
          <img src="/img/vantpadje_wit.svg" alt="" class="mt-img" width="345px" />
        </div>
      </div>
    </div>
    <CTAFIXED />
    <section class="imageblock bg-gray about-2" style="background-image: url(img/bg.png)">
      <div class="container-fluid container-up test">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-6 content-column-left">
            <section class="content-block lazy content-block-text odd">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?
                </h2>
                <h3>
                  Henja van den Elzen (JDE): "Geloof me, er wordt heel wat koffie geproefd in ons team."

                </h3>

              </div>

              <div class="content-text">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo.
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie"
                  class="btn mb-30 btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/edit_DSC8365.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <section class="app-grid lazy hero-general space-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-5 image-column m-dnone">
            <section class="content-block lazy">
              <div class="content-image">


            <img src="https://senseo.nadruksubsites.be/img/Sonja_IMG_0087.png" class="img-responsive" />

              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-6 col-lg-6
              offset-lg-1 offset-md-1
              content-column
            ">
            <section class="content-block lazy content-block-text middle">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  "Een op drie Belgische gezinnen heeft een Senseo-apparaat. Dat is echt ongelofelijk"
                </h2>
                <h3>Sonja Wegscheider (JDE): "Senseo heeft de manier waarop de Belg koffie drinkt volledig veranderd...
                </h3>
              </div>

              <div class="content-text">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe Senseo de manier
                  waarop we koffie drinken heeft veranderd, zegt Sonja Wegscheider van Jacobs Douwe Egberts. </p>
              </div>

              <div class="content-buttons">
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk"
                  class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
              dnone
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section> 
    <section class="imageblock bg-gray about-2" style="background-image: url(img/bg.png)">
      <div class="container-fluid container-up test">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-6 content-column-left">
            <section class="content-block lazy content-block-text odd">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Wat is de voordeligste manier om koffie te zetten?
                </h2>
                <h3>Een lekker kopje koffie voor de laagste prijs? Zo maak je dat</h3>

              </div>

              <div class="content-text">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet alleen: drie op vier Belgen drinkt elke dag
                  koffie. Maar wat is de voordeligste manier om thuis koffie te zetten?
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten" class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/senseo.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section class="app-grid lazy hero-general space-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-5 image-column m-dnone">
            <section class="content-block lazy">
              <div class="content-image">


                <img src="img/ThierryDerycke.png" class="img-responsive" />

              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-6 col-lg-6
              offset-lg-1 offset-md-1
              content-column
            ">
            <section class="content-block lazy content-block-text middle">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?
                </h2>
                <h3>Dit is het meest duurzame koffiezetapparaat, volgens Thierry (52)</h3>
              </div>

              <div class="content-text">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van de gebruikers van het eerste uur is
                  Thierry. Wat zijn zijn ervaringen met Senseo?
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie" class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
              dnone
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section class="imageblock bg-gray about-2" style="background-image: url(img/bg.png)">
      <div class="container-fluid container-up test">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-6 content-column-left">
            <section class="content-block lazy content-block-text odd">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Hoe drinken Belgen thuis hun koffie?
                </h2>
                <h3>Zo drinken Belgen het liefst hun koffie</h3>
              </div>

              <div class="content-text">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie. Sinds de
                  corona-pandemie zit het koffiegebruik thuis zelfs enorm in de
                  lift. En goed nieuws: we zijn ons ook steeds meer bewust van
                  de impact van koffie op het milieu.
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie" class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/3790016_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <section class="app-grid lazy hero-general space-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-5 image-column m-dnone">
            <section class="content-block lazy">
              <div class="content-image">


                <img src="img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />

              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-6 col-lg-6
              offset-lg-1 offset-md-1
              content-column
            ">
            <section class="content-block lazy content-block-text middle">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Hoe onze manier om koffie te zetten veranderd is
                </h2>
                <h3>Wat is jouw favoriete koffie van ’t padje?</h3>
              </div>

              <div class="content-text">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft de manier waarop we
                  koffie drinken voorgoed veranderd. Al zijn er ondertussen nog
                  meer nieuwigheden te ontdekken die je kopje koffie nog net
                  iets smaakvoller maken…
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje" class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-5 col-lg-5
              offset-lg-1 offset-md-1
              image-column
              dnone
            ">
            <section class="content-block lazy">
              <div class="content-image">

                <img src="img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />

              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <section class="imageblock bg-gray about-2 resposive" style="background-image: url(img/bg.png)">
      <div class="container-fluid container-up test">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-6 content-column-left">
            <section class="content-block lazy content-block-text odd">
              <div class="content-header">
                <h2 class="h7 color-primary">
                  Hoe duurzaam is jouw dagelijkse koffie?
                </h2>
                <h3>Belgen verkiezen duurzame koffie, jij toch ook?</h3>
              </div>

              <div class="content-text">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt, ook de producenten van
                  koffiemachines. Maar weet jij hoe duurzaam jouw kopje koffie
                  nu echt is? Ontdek het hier en kom te weten hoe jij duurzamere
                  koffiekeuzes kan maken.
                </p>
              </div>

              <div class="content-buttons">
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook" class="btn btn-primary">Lees meer</a>
              </div>
            </section>
          </div>
          <div class="
              col-sm-12 col-md-6 col-lg-5
              offset-lg-1 offset-md-1
              image-column
            ">
            <section class="content-block lazy">
              <div class="content-image">
                <img src="img/3790019_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" class="img-responsive" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <div class="home-footer">
      <Footer />
    </div>
  </div>
</template>



<script>
import Footer from "../components/Footer.vue";
import CTAFIXED from "../components/CTAFIXED.vue";

export default {
  components: {
    Footer,
    CTAFIXED,
  },
  mounted() {
    window.scrollTo(0, 0);


  },


};
</script>

<style scoped>
.banner-1 {
  font-family: "Senseo-Sentence,Helvetica,Arial,sans-serif";
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 194px;
  position: absolute;
  bottom: 0;
}

.banner-img-home {
  height: 570px !important;
  width: 100% !important;
  background-repeat: no-repeat;
  position: relative;
  background-position: 53% 69%;
  background-size: cover;
}

.mt-img-content {
  width: 100%;
  position: absolute;
  left: 0;
  top: 20%;
}

@media only screen and (min-width: 1280px) {
  .banner-img-home {
    height: 570px;
  }
}

.mt-img {

  margin: 0 !important;
}

@media (max-width: 784px) {
  .banner-img-home {
    height: 57Opx;
  }

  .mt-img {

    margin: 0 !important;
  }
}

@media (max-width: 768px) {

  .middle {
    width: 100% !important;
  }
}
</style>