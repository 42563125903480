<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="een" />
    <CTAFIXED value="two" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon-green.svg" width="50" />
            <h3>Wat is jouw favoriete koffie van ’t padje?</h3>
          </div>

          <a class="link"
            href="https://www.senseo.be/?utm_source=article2&utm_medium=referral&utm_campaign=Wave1&utm_id=Native&utm_content=header-image"
            target="_blank">
            <img src="img/3790020_99_2020_SEN_SIS_ERT_18_POS_non-applicable_lifestyle.png" />
          </a>
          <div class="intro">
            <strong>
              Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
              Belgische markt. En die innovatie heeft de manier waarop we koffie
              drinken voorgoed veranderd. Al zijn er ondertussen nog meer
              nieuwigheden te ontdekken die je kopje koffie nog net iets
              smaakvoller maken…
            </strong>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  Voor Senseo op de markt verscheen, dronken de meeste Belgen
                  thuis koffie zoals oma: filterkoffie gemaakt met een
                  traditioneel koffiezetapparaat. Alleen op restaurant of café
                  zag je een grote, peperdure espressomachine die koffie met een
                  lekker schuimlaagje maakte.
                </p>
                <p>
                  En toen kwam Senseo, de eerste koffiemachine met padjes. Dat
                  revolutionaire toestel bood het beste van twee werelden. Je
                  maakte even snel een kopje koffie als met een espressoapparaat
                  én je kreeg er een heerlijk schuimlaagje bovenop. Het was
                  zelfs supermakkelijk in gebruik en betaalbaarder dan die
                  professionele koffiemachines.
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Het succes van Senseo</h4>
              </div>

              <div class="content-text">
                <p>
                  Het geheim van die Senseo-koffiemachine zit hem eigenlijk in
                  de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262936&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">koffiepads</a>. Die bevatten exact de hoeveelheid koffie die nodig is voor
                  1 kopje koffie. Geen gedoe meer met losse koffie en filters:
                  padje plaatsen, knopje drukken en klaar.
                </p>
                <p>
                  De koffie in de padjes is bovendien speciaal gebrand en
                  gemalen voor het beste resultaat. De Senseo-machine zelf
                  verwarmt elke keer weer de juiste hoeveelheid water tot de
                  juiste temperatuur en stuurt die door het padje. Hierdoor is
                  het perfecte kopje koffie altijd gegarandeerd.
                </p>
                <p>
                  Niet verwonderlijk dus dat Senseo meteen een groot succes
                  werd, en zelfs nog steeds is! Anno 2022 is Senseo nog altijd
                  het bestverkopende 'single dose' systeem. Liefst 1,4 miljoen
                  Belgen gebruiken minstens één keer per maand een Senseo.
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <img class="image-content" src="img/_SENBOOK_GLASSES_Tilt_WithS_contactsheet.png" alt="" />
              <div class="content-header mt-5">
                <h4>Voor elk wat wils</h4>
              </div>

              <div class="content-text">
                <p>
                  Het allereerste Senseo-toestel twintig jaar geleden was de
                  eenvoud zelve. Je had de aan-uitknop en een knop om één dan
                  wel twee kopjes koffie te zetten. Je kon kiezen uit drie
                  kleuren (zwart, wit of blauw), en dat was het. Die
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262441&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Original</a>
                  kun je vandaag nog steeds kopen. Al staat de tijd niet stil
                  bij Senseo. Innovatieve technologieën zorgen ervoor dat je nog
                  smaakvollere koffie thuis kunt zetten. Zo laat de unieke
                  koffieboost-technologie het hete water gelijkmatig door 45
                  gaatjes lopen, waardoor je nog beter de volle, intense smaak
                  proeft van het Senseo padje. En door de Crema Plus-technologie
                  krijg je een heerlijk fluweelzacht crèmelaagje op je koffie.
                </p>
                <p>
                  Heb jij je koffie soms graag wat straffer, omdat je een
                  opkikker kunt gebruiken? Ook dat kan met Senseo. Bij de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249418&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Original Plus </a>kun je kiezen tussen een lungo of een sterkere variant. Nog
                  meer keuzemogelijkheden vind je op het allernieuwste toestel
                  in de familie, de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262939&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Select.</a>
                  Via de Intensity Plus-schakelaar op dat toestel kies je tussen
                  een milde koffie, een sterke kop koffie of een krachtige
                  espresso.
                </p>
              </div>
            </section>
          </div>
          <img src="img/highlights_border_0000s_0011_koffieboost55.png" class="image-content two" width="420" alt="" />
          <img src="img/highlights_border_0000s_0012_cremaaplus55.png" class="image-content two" width="420" alt="" />

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  Sinds de introductie van de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262444&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Original</a>
                  zijn koffiebereidingen met melk ook erg populair geworden.
                  Voor liefhebbers van cappuccino, latte macchiato of café latte
                  is er de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5947249421&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Latte Duo.</a>
                  Deze kan melk opschuimen. En superhandig: het melkreservoir
                  zit niet vast in het toestel. Je kan het na gebruik in de
                  koelkast zetten om de melk vers te houden.
                </p>
                <p>
                  Heel handig allemaal om voor één of twee personen koffie te
                  maken. Maar wat doe je als je familie op bezoek hebt voor
                  taart en meer koffie nodig hebt? Dan is de
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262942&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Switch</a>
                  de oplossing. Dit is het enige 2-in-1 koffiezetapparaat waarin
                  je zowel een individuele kop koffie (met pads) als een hele
                  kan (met gemalen filterkoffie) kunt zetten.
                </p>
              </div>
            </section>
          </div>
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
           <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                               postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"
                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div> 
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie  " color="footer-banner-one.svg"
                img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt, ook de producenten van
                  koffiemachines...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet
                  alleen: drie op vier Belgen drinkt elke dag koffie. Maar wat
                  is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="two" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }

  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #009b39;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #009b39;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #e14337;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}
</style>
<style >
.dashed-2 {
  border: none;
  height: 1px;
  background: #000;
  width: 11vw;
  height: 1px;
  background: linear-gradient(90deg, #d00e2f 45%, rgba(0, 0, 0, 0) 0) repeat-x right bottom/1.4rem 1px;
}

.strip {
  display: flex;
  padding-left: 0;
}

.more {
  font-size: 17px;
  line-height: 2;
  white-space: nowrap;
}

.btn-5 {
  font-size: 12px;
}
</style>