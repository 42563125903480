<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="vijf" />
    <CTAFIXED value="vijf" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon-donker.svg" width="50" />
            <h3>Wat is de voordeligste manier om koffie te zetten?</h3>
          </div>

          <img src="img/senseo.png" />

          <div class="intro">
            <strong>
              Start jij je dag ook met een kopje koffie? Je bent niet alleen:
              drie op vier Belgen drinkt elke dag koffie. Maar wat is de
              voordeligste manier om thuis koffie te zetten?
            </strong>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  Je weet vast dat er verschillende manieren bestaan om koffie
                  te zetten. Maar wist je dat die systemen ook erg verschillen
                  als je kijkt naar de prijs per tas?
                </p>
                <p>
                  Voor dit artikel vergelijken we de drie populairste systemen
                  om koffie te zetten: een koffiezetapparaat met filterkoffie,
                  een koffiezetautomaat met
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=5945262936&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">koffiepads van Senseo</a>
                  en een koffiezetautomaat met capsules.
                </p>
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <div class="content-header">
                  <h4>De prijs per tas</h4>
                </div>

                <p>
                  Voor koffiepads en capsules is de prijs per tas makkelijk te
                  berekenen. Je kijkt gewoon naar de prijs per pad of capsule.
                  Die bevat exact de hoeveelheid koffie die nodig is voor één
                  tas. Als je kijkt naar de gemiddelde prijzen in de
                  detailhandel*, kost een kop zwarte koffie met originele Senseo
                  padjes 10,7 cent. Bij tijdelijke promoties betaal je voor één
                  padje soms zelfs maar 9,1 cent.
                </p>

                <p>
                  Volgens cijfers van onderzoeksbureau NielsenIQ is de prijs
                  voor een kopje zwarte koffie met capsules in de detailhandel
                  gemiddeld meer dan twee keer hoger dan voor pads (zonder
                  rekening te houden met tijdelijke promoties). Je bent dus
                  gemiddeld goedkoper af met pads. Hoe meer tassen je drinkt,
                  hoe meer je bespaart met pads.
                </p>
                <img src="img/aboutourcoffee.png" class="image-content" alt="" />
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Filterkoffie: in principe goedkoper, maar….</h4>
              </div>

              <div class="content-text">
                <p>
                  Bij filterkoffie is de rekening iets moeilijker te maken. In
                  principe kost een kop koffie gemiddeld tussen 6 cent als je
                  gemalen koffie gebruikt en 11 cent als je bonen gebruikt (ook
                  weer zonder rekening te houden met promoties). Maar: de
                  berekening is gebaseerd op een normale hoeveelheid koffie.
                  Schep je een kwart meer koffie in je filter omdat je die graag
                  sterk hebt, dan wordt de prijs per tas ook hoger.
                </p>
                <p>
                  Bovendien gaat de berekening ervan uit dat je exact evenveel
                  koffie zet als je nodig hebt – met andere woorden, dat er
                  niets verloren gaat. Als je soms te veel koffie zet en de
                  helft van een kan moet weggieten, loopt de prijs per tas snel
                  op. Een tas filterkoffie kan in dat geval duurder zijn dan een
                  tas Senseo.
                </p>

                <img src="img/senseo-a.png" class="image-content" alt="" />

              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Wat met het toestel zelf?</h4>
              </div>

              <div class="content-text">
                <p>
                  Ook het toestel zelf speelt een rol in de prijs per kopje.
                  Maar dat valt best mee. Het merendeel van de in België
                  verkochte 'single serve' toestellen kost tussen de 40 en 80
                  euro**. Een Senseo-apparaat gaat volgens cijfers van Senseo
                  zelfs makkelijk vijf tot zes jaar mee. Als je twee kopjes per
                  dag zet of pakweg 700 kopjes per jaar, is dat goed voor 3.500
                  tot 4.200 kopjes tijdens de hele levensduur van het toestel.
                  Een toestel uit de prijsvork 40 tot 80 euro kost je dus
                  (afgerond) tussen een cent en een halve cent per kopje.
                </p>
                <p>
                  De laatste factor is het stroomverbruik. Een koffiezetapparaat
                  heeft energie nodig om water op te warmen. Hier zijn 'single
                  serve' systemen in het voordeel, omdat die steeds exact de
                  juiste hoeveelheid water verwarmen – en geen druppel meer. Als
                  je soms te veel filterkoffie zet, heb je ook te veel water
                  verwarmd om koffie te zetten die je nooit opdrinkt. Dat is
                  verspilling van energie (en koffie!).
                </p>
                <p>
                  Dat is ook de reden waarom het Senseo-systeem met padjes
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6011373357&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">een lage ecologische impact heeft.</a>
                  Als je –
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6011371119&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">net als twee op drie koffiedrinkende Belgen</a>
                  – duurzaamheid belangrijk vindt, ligt de keuze voor Senseo dus
                  voor de hand.
                </p>
                <p>
                  Senseo maakt zijn koffiezetapparaten bovendien steeds
                  zuiniger. De
                  <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6013271510&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Select</a>
                  bijvoorbeeld verbruikt 52% minder energie dan zijn voorganger,
                  dankzij een knop voor onmiddellijk starten en onmiddellijk
                  uitschakelen. Zo bespaar je meteen op je
                  elektriciteitsfactuur, en heb je toch in geen tijd een
                  heerlijk en betaalbaar kopje koffie van 't padje klaar.
                </p>

                <p class="cursief">
                  * We baseren ons op onafhankelijke cijfers van marktonderzoeker NielsenIQ.
                </p>
                <p class="cursief">
                  ** We baseren ons op onafhankelijke cijfers van marktonderzoeker GfK
                </p>
              </div>
            </section>
          </div>


        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall
                               postTitle="20 jaar Senseo: koffie drinken was nooit meer hetzelfde"

                color="groen" img="groen-zeven">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe...

                </p>
                <a href="/#/een-op-drie-belgische-gezinnen-heeft-een-senseo-apparaat-dat-is-echt-ongelofelijk">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div> 
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie " color="bruin" img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie. Sinds de
                  corona-pandemie zit het koffiegebruik...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is jouw favoriete koffie van ’t padje?" color="green" img="green">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twintig jaar geleden kwam de eerste Senseo koffiemachine op de
                  Belgische markt. En die innovatie heeft...
                </p>
                <a href="/#/wat-is-jouw-favoriete-koffie-van-t-padje">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>

          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="vier" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
   if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.link {
  color: #4a1918;
  font-weight: bold;
  text-decoration: underline !important;
}

.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #4a1a18;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #4a1a18;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #4a1a18;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}

.cursief {
  font-size: 12px;
  font-style: italic;
  margin: 0;
}
</style>
