<template>
  <div>
    <div class="header" id="myHeader">
      <center>
        <h2 style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            color: #000;
          ">
          Aangeboden door Senseo
        </h2>
      </center>
    </div>
    <Banner value="zeven" />
    <CTAFIXED value="zeven" />

    <div class="container py-40">
      <div class="row">
        <div class="col-md-8 col-lg-8 content-container">
          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
          <div class="titel">
            <img src="img/icon-green.svg" width="50" />
            <h3>Sonja Wegscheider (JDE): "Senseo heeft de manier waarop de Belg koffie drinkt volledig veranderd. Ik ben
              er heel trots op dat we dat voor mekaar hebben gekregen."</h3>
          </div>

          <a class="link"
            href="http://pubads.g.doubleclick.net/gampad/clk?id=6095787082&iu=/22306128931/DFPAudiencePixel"
            target="_blank">
            <img src="https://senseo.nadruksubsites.be/img/Sonja_IMG_0087.png" />

          </a>
          <div class="intro">
            <strong>
              Twintig jaar geleden kwam Senseo op de Belgische markt. We beseffen niet meer hoe Senseo de manier waarop
              we koffie drinken heeft veranderd, zegt Sonja Wegscheider van Jacobs Douwe Egberts.
            </strong>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-text">
                <p>
                  Sonja Wegscheider werkt al meer dan 25 jaar voor koffiebedrijf Jacobs Douwe Egberts (JDE), waar ze
                  sinds vorig jaar de Belgische retail-organisatie leidt. Zeventien jaar geleden was ze als
                  marketingmanager verantwoordelijk voor Senseo.
                </p>
                <p>
                  "Voor de komst van Senseo zat er niet veel beweging in de koffiemarkt," herinnert Sonja zich. "Thuis
                  dronken mensen klassieke zwarte filterkoffie. Op café of restaurant kreeg je een espresso die gemaakt
                  werd met een groot en ingewikkeld apparaat. Senseo heeft die ervaring van bijzondere koffie bij de
                  mensen thuis gebracht. Dat was echt wel revolutionair: plots kon je ook thuis een kopje koffie met een
                  laagje crema drinken. En je moest niet elke keer een hele pot koffie zetten: met Senseo maakte je
                  kopje per kopje, en elk kopje werd vers gezet en was perfect. Je kon ook 2 kopjes tegelijkertijd
                  maken. Zo heeft Senseo de koffiemarkt nieuw leven ingeblazen."
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header">
                <h4>Een enorm succes</h4>
              </div>

              <div class="content-text">

                <p>
                  Senseo is gegroeid uit een samenwerking tussen koffiebedrijf Douwe Egberts en technologiebedrijf
                  Philips, dat de machines ontwikkelde. "We hebben daar zelfs een reclamespot over gemaakt, waarin
                  iemand van Philips en iemand van Douwe Egberts tegen elkaar botsen, waardoor de plannen die ze dragen
                  op straat vallen. En door die plannen naast elkaar te zien, krijgen ze het idee voor Senseo. En
                  eigenlijk is het ook echt zo gegaan: beide bedrijven hebben hun troeven samengelegd om Senseo te
                  ontwikkelen."
                </p>
                <p>
                  Bij de start was er maar één model verkrijgbaar, de iconische ronde blauwe <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6095787070&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">Senseo Classic</a> die nog altijd bestaat. "We hadden berekend dat een machine
                  ongeveer zeven jaar zou meegaan. Maar er zijn machines van het eerste uur die nog altijd gebruikt
                  worden, zo goed was de kwaliteit toen al", vertelt Sonja.
                </p>

                <p>

                </p>
                <p>
                  Senseo was van bij het begin een enorm succes. "Op een bepaald moment zijn we gestopt met reclame
                  maken, omdat we gewoon niet voldoende machines en pads konden leveren," lacht Sonja.
                </p>
                <p>
                  Sindsdien is het gamma uitgebreid met verschillende modellen, waaronder ook toestellen die
                  melkbereidingen kunnen maken of machines <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6095787073&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">die voor een groot deel met gerecycleerd plastic worden gemaakt.</a> Het assortiment
                  pads is gegroeid van de originele vier, tot een uitgebreid gamma met ook melkbereidingen en bijzondere
                  koffies.
                </p>
                <p>
                  Er zijn ook grotere verpakkingen bijgekomen. De reden? "We hadden echt onderschat hoe populair Senseo
                  zou worden", zegt Sonja. "In het begin had je alleen kleine verpakkingen van 18 pads, want we dachten
                  dat mensen daar lang mee zouden toekomen. Niet dus! Tegenwoordig zijn er ook verpakkingen van 54 en in
                  bepaalde landen zelfs 72 pads."
                </p>
              </div>
            </section>
          </div>

          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header ">
                <h4>Unieke troeven</h4>
              </div>

              <div class="content-text">
                <p>
                  Sinds de introductie van Senseo zijn er nog andere 'single serve' systemen op de markt gekomen. Toch
                  heeft het Senseo-systeem nog steeds unieke troeven, zegt Sonja. "Ik heb een Senseo en ook een machine
                  met capsules – JDE maakt ook koffie in capsules, en ik wil die dan ook wel kunnen proeven. Maar ik
                  merk toch dat ik mijn Senseo het vaakst gebruik."
                </p>
                <p>
                  "Je kan de technologie van Senseo niet zomaar vergelijken met capsules of met een espressoapparaat:
                  wij maken geen koffie onder hoge druk. Maar Senseo, dat is een lekkere, goed drinkbare koffie die
                  bovendien goed betaalbaar is - zowel het toestel zelf als de pads. Als je alles vergelijkt is Senseo
                  een zeer voordelig systeem, en dat willen we zo houden. En ook voor duurzaamheid scoren we goed, zowel
                  voor de machine, de pads, de verpakking als de koffie zelf."
                </p>
              </div>
            </section>
          </div>
          <div class="upper-content">
            <section class="content details lazy text">
              <div class="content-header ">
                <h4>Blijven innoveren</h4>
              </div>

              <div class="content-text">
                <p>
                  Sinds 2002 heeft Senseo altijd goed geluisterd naar wat gebruikers willen, en het aanbod daaraan
                  aangepast. Het jongste voorbeeld daarvan zijn de compacte pads, vertelt Sonja. "Mensen proberen graag
                  eens iets anders, en we kregen de vraag naar een sterkere koffie. Met de <a class="link"
                    href="http://pubads.g.doubleclick.net/gampad/clk?id=6095787085&iu=/22306128931/DFPAudiencePixel"
                    target="_blank">nieuwe compacte pads</a> kan je
                  op sommige machines ook instellen dat er minder water wordt gebruikt, zodat je een espresso-achtige
                  koffie krijgt. Er zijn compacte pads op basis van Origine-koffie, voor de echte koffieliefhebber."
                </p>
                <p>
                  Door die constante innovatie is Senseo ook vandaag nog relevant. Dat blijkt ook uit de cijfers: het is
                  met voorsprong het populairste single serve systeem in België. "Een op drie Belgische gezinnen heeft
                  een Senseo-apparaat. Dat is echt ongelofelijk. Als je al zo lang voor een bedrijf werkt als ik,
                  vergeet je al eens dat Senseo de manier waarop de Belg koffie drinkt volledig veranderd heeft. Ik ben
                  er heel trots op dat we dat voor mekaar hebben gekregen." </p>
              </div>

            </section>

          </div>


          <div class="titel">
            <div>
              <a data-v-00d563dc="" href="/" class="back">« Terug naar overzicht</a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 offset-md-1 content-container">
          <section class="content strip lazy">
            <div class="content-header">
              <h5 class="color-primary more">meer artikels</h5>
            </div>
            <div>
              <hr class="dashed-2" />
            </div>
          </section>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat komt er kijken bij de ontwikkeling van nieuwe Senseo-koffie?" color="beige"
                img="beige">
                >
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Henja van den Elzen is bij koffiebedrijf Jacobs Douwe Egberts (JDE) verantwoordelijk voor de
                  productontwikkeling van Senseo...
                </p>
                <a href="/#/wat-komt-er-kijken-bij-de-ontwikkeling-van-nieuwe-senseo-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Zo drinken Belgen het liefst hun koffie  " color="footer-banner-one.svg"
                img="bruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Wist je dat Belgen echte koffiedrinkers zijn? Drie op vier
                  landgenoten drinkt elke dag minstens één kop koffie...
                </p>
                <a href="/#/zo-drinken-belgen-het-liefst-hun-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Belgen verkiezen duurzame koffie, jij toch ook?" color="geel" img="geel">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Twee op drie Belgische koffiedrinkers willen het liefst
                  duurzame koffie en dat iedereen in de koffieketen zijn
                  verantwoordelijkheid neemt, ook de producenten van
                  koffiemachines...
                </p>
                <a href="/#/belgen-verkiezen-duurzame-koffie-jij-toch-ook">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>
            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Hoe maakt Thierry (52) zijn dagelijkse kopje koffie?" color="red" img="red">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Dit jaar is Senseo twintig jaar op de Belgische markt. Een van
                  de gebruikers van het eerste uur is Thierry. Wat zijn zijn
                  ervaringen met Senseo?
                </p>
                <a href="/#/hoe-maakt-thierry-52-zijn-dagelijkse-kopje-koffie">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
          <div class="product-range-product" data-process-key="machines-filter" style="display: block">
            <div class="product-image">
              <BannerSmall postTitle="Wat is de voordeligste manier om koffie te zetten?" color="donkerbruin"
                img="donkerbruin">
              </BannerSmall>
            </div>
            <div class="product-information pt-20">
              <div class="flex-titel">
                <p>
                  Start jij je dag ook met een kopje koffie? Je bent niet
                  alleen: drie op vier Belgen drinkt elke dag koffie. Maar wat
                  is de voordeligste manier om thuis koffie te zetten?
                </p>
                <a href="/#/wat-is-de-voordeligste-manier-om-koffie-te-zetten">
                  <h5 class="btn-5">Lees meer</h5>
                </a>
              </div>
            </div>

            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer cta="zeven" />
  </div>
</template>

<script>
import BannerSmall from "../BannerSmall.vue";
import Banner from "../Banner.vue";
import Footer from "../Footer.vue";
import CTAFIXED from "../CTAFIXED.vue";

export default {
  components: {
    Banner,
    Footer,
    CTAFIXED,
    BannerSmall,
  },
  mounted() {
    window.scrollTo(0, 0);
   if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }

  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.content.details {
  text-align: left;
}

.intro {
  text-align: left;
  padding: 20px;
  font-family: "Flama-Basic", sans-serif;
  font-weight: bold;
  padding-top: 50px;
}

strong,
.content-header h4 {
  color: #009b39;
}

.content-container {
  padding: 50px 0;
}

h3 {
  font-family: "Flama-Basic", sans-serif;
  color: #009b39;
  padding: 20px;
  font-weight: bold;
  text-align: left;
}
.link {
  color: #009b39;
  font-weight: bold;
  text-decoration: underline !important;
}

.titel {
  display: flex;
}

.product-listing .product-range-product .product-image img {
  max-width: 65%;
  max-height: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  background: #e6e7e963;
}

.product-range-product {
  background: #e6e7e963;
  margin-bottom: 20px;
}

.h7 {
  font: 400 20px/25px "Flama-Basic", sans-serif;
  letter-spacing: 0;
  color: #e14337;
}

.product-listing .product-range-product .product-information {
  padding: 26px;
  text-align: left !important;
}

.product-listing .product-range-product .highlight {
  padding: 20px 0;
}

.flex-titel {
  padding: 20px;
  text-align: left;
}

h4 {
  font-weight: 400 !important;
  font-size: 20px !important;
}
</style>
<style >
.dashed-2 {
  border: none;
  height: 1px;
  background: #000;
  width: 11vw;
  height: 1px;
  background: linear-gradient(90deg, #d00e2f 45%, rgba(0, 0, 0, 0) 0) repeat-x right bottom/1.4rem 1px;
}

.strip {
  display: flex;
  padding-left: 0;
}

.more {
  font-size: 17px;
  line-height: 2;
  white-space: nowrap;
}

.btn-5 {
  font-size: 12px;
}
</style>