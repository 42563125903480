<template>
  <div>
    <section class="app-grid lazy register-homepage-block top-cup mt-5" style="
        background-image: url(img/register-texture.png);
        margin-bottom: 0px !important;
      ">
      <img src="/img/coffee-cup.png" width="300" class="coffee-img" height="300" alt="" />
      <div class="container">
        <div class="row">
          <div class="
              col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
              right-content-mpbil
            ">
            <section class="content lazy mt-2">
              <div class="content-header">
                <h4 class="tr">{{ text }}</h4>
              </div>

              <div class="content-text"></div>

              <div class="content-buttons">
                <a :href="url == 'one' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262933&iu=/22306128931/DFPAudiencePixel' : url == 'two' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262447&iu=/22306128931/DFPAudiencePixel' : url == 'vier' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6013271504&iu=/22306128931/DFPAudiencePixel' : url == 'vijf' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6011373354&iu=/22306128931/DFPAudiencePixel' :  url == 'zes' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787067&iu=/22306128931/DFPAudiencePixel' : url == 'zeven' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787079&iu=/22306128931/DFPAudiencePixel' : 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262456&iu=/22306128931/DFPAudiencePixel'"
                  class="btn btn-primary cta">
                  <p>
                    Klik hier en ontdek het hele gamma
                  </p>
                  <p>
                    Senseo koffiemachines en pads.
                  </p>

                </a>
              </div>
            </section>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left-content"
            style="background-image: url(img/register-texture-mobile.png)">
            <section class="content lazy">
              <div class="content-header">
                <h2 class="cta">Nóg meer <br />koffieplezier!</h2>
              </div>
            </section>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right-content">
            <section class="content lazy mt-2">
              <div class="content-header">
                <h4 class="tr">{{ text }}</h4>
              </div>

              <div class="content-text"></div>

              <div class="content-buttons">
           
                <a target="_blank"
                  :href="url == 'one' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262933&iu=/22306128931/DFPAudiencePixel' : url == 'two' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262447&iu=/22306128931/DFPAudiencePixel' : url == 'vier' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6013271504&iu=/22306128931/DFPAudiencePixel' : url == 'vijf' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6013271513&iu=/22306128931/DFPAudiencePixel' : url == 'zes' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787067&iu=/22306128931/DFPAudiencePixel' : url == 'zeven' ? 'http://pubads.g.doubleclick.net/gampad/clk?id=6095787079&iu=/22306128931/DFPAudiencePixel' : 'http://pubads.g.doubleclick.net/gampad/clk?id=5945262456&iu=/22306128931/DFPAudiencePixel'"
                  class="btn btn-primary">Klik hier en ontdek het hele gamma <br> Senseo koffiemachines en
                  pads.</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ["text", "url"],

};
</script>
<style scoped>
.btn.btn-primary {
  margin-top: 0 !important;
  font-size: 19px !important;
  background-color: #d00e2f;
  color: #fff;
  border-radius: 20.5px;
  padding: 0 24px;
  line-height: 21px;
  border: none;
  margin-top: 30px !important;
  height: 67px !important;

  padding-top: 11px !important;
}

@media (max-width: 784px) {
  .btn.btn-primary {
    margin-top: 0 !important;
    font-size: 19px !important;
    background-color: #d00e2f;
    color: #fff;
    border-radius: 20.5px;
    padding: 0 24px;
    line-height: 9px;
    border: none;
    margin-top: 30px !important;
    height: 67px !important;

    padding-top: 16px !important;
  }
}
</style>


